import React, { useEffect, useState } from 'react';
import TitlePage from "../../common/TitlePage";
import { Button, Form, Input, message, Modal, TreeSelect } from "antd";
import DataTable from "../../common/DataTable";
import moment from "moment";
import { CiSettings } from "react-icons/ci";
import { FiTrash2 } from "react-icons/fi";
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  setCurrentPage,
  updateCategory
} from "../../app/features/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import slug from "../../utils/slug";
import { transformToThreeLevelData, transformToTwoLevelData } from "../../utils/utils";

const Categories = () => {
  const dispatch = useDispatch();
  const {
    categories,
    loading,
    currentPage,
    pageSize,
    totalCount
  } = useSelector(state => state.category);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [generatedSlug, setGeneratedSlug] = useState('');
  const [isSlugEdited, setIsSlugEdited] = useState(false);
  const [form] = Form.useForm();
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    loadCategories();
  }, [currentPage]);

  const loadCategories = () => {
    dispatch(fetchCategories({
      page: currentPage,
      pageSize
    }));
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    form.setFieldsValue({ name });

    if (!isSlugEdited) {
      const newSlug = slug(name);
      setGeneratedSlug(newSlug);
      form.setFieldsValue({ slugUrl: newSlug });
    }
  };

  const handleSlugChange = (e) => {
    setIsSlugEdited(true);
    const value = e.target.value;
    setGeneratedSlug(value);
  };

  const showModal = (category = null) => {
    setEditingCategory(category);
    setIsSlugEdited(false);
    if (category) {
      form.setFieldsValue({
        name: category.name,
        description: category.description,
        parentId: category.parentId,
        slugUrl: category.slugUrl,
        sortNumber: category.sortNumber
      });
      setGeneratedSlug(category.slugUrl);
    } else {
      form.resetFields();
      setGeneratedSlug('');
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingCategory(null);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      if (editingCategory && !editingCategory.parentId && values.parentId === editingCategory.id) {
        message.error('Không thể chọn chính danh mục này làm danh mục cha!');
        return;
      }

      if (editingCategory) {
        await dispatch(updateCategory({
          id: editingCategory.id,
          ...values
        })).unwrap();
        message.success('Cập nhật danh mục thành công!');
      } else {
        await dispatch(createCategory(values)).unwrap();
        message.success('Thêm danh mục thành công!');
      }
      handleCancel();
      loadCategories();
    } catch (err) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại!');
    }
  };

  const confirmDelete = async (record) => {
    Modal.confirm({
      title: 'Xác nhận xóa',
      content: 'Bạn có chắc chắn muốn xóa danh mục này?',
      okText: 'Xóa',
      cancelText: 'Hủy',
      okType: 'danger',
      onOk: async () => {
        try {
          await dispatch(deleteCategory(record.id)).unwrap();
          message.success('Xóa danh mục thành công!');
          loadCategories();
        } catch (err) {
          message.error('Có lỗi xảy ra. Vui lòng thử lại!');
        }
      },
    });
  };

  const cols = [
    {
      title: "Tên danh mục",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span className={`${record.parentId ? 'ml-8 text-gray-600' : 'font-medium'}`}>
          {text}
        </span>
      )
    },
    {
      title: "Slug URL",
      dataIndex: "slugUrl",
      key: "slugUrl",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Thứ tự",
      dataIndex: "sortNumber",
      key: "sortNumber",
      align: "center",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      key: "createDate",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "Hành động",
      key: "action",
      render: (_, record) => (
        <div className="flex items-center space-x-2">
          <Button
            type="primary"
            onClick={() => showModal(record)}
            className="flex items-center gap-1"
          >
            <CiSettings/>
            <span>Chỉnh sửa</span>
          </Button>
          <Button
            type="primary"
            danger
            disabled={record.children?.length > 0}
            onClick={() => confirmDelete(record)}
            className="flex items-center gap-1"
          >
            <FiTrash2/>
            <span>Xóa</span>
          </Button>
        </div>
      ),
    },
  ];

  const transformToTreeData = (data) => {
    return data.map(item => ({
      title: item.name,
      value: item.id,
      key: item.id,
      disabled: editingCategory?.id === item.id,
      children: item.children ? transformToTreeData(item.children) : undefined
    }));
  };

  const treeData = categories ? transformToTreeData(transformToTwoLevelData(categories)) : [];

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <TitlePage>Quản lý danh mục</TitlePage>
        <Button type="primary" onClick={() => showModal()}>
          Thêm danh mục
        </Button>
      </div>

      <DataTable
        className="categories-table"
        listData={transformToThreeLevelData(categories)}
        columns={cols}
        loadingTable={loading}
        totalPage={totalCount}
        indexPage={currentPage}
        onPageChange={(page) => dispatch(setCurrentPage(page))}
        expandable={{
          defaultExpandAllRows: true,
          expandRowByClick: true
        }}
        showPagination={false}
      />

      <Modal
        title={editingCategory ? "Chỉnh sửa danh mục" : "Thêm danh mục mới"}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            label="Tên danh mục"
            rules={[{ required: true, message: 'Vui lòng nhập tên danh mục!' }]}
          >
            <Input onChange={handleNameChange} />
          </Form.Item>

          <Form.Item
            name="slugUrl"
            label="Slug URL"
            rules={[
              { required: true, message: 'Slug URL không được để trống!' },
              {
                pattern: /^[a-z0-9_-]+$/,
                message: 'Slug chỉ được chứa chữ thường, số và dấu gạch dưới/ngang'
              }
            ]}
            tooltip="Slug sẽ được tự động tạo từ tên danh mục. Bạn có thể chỉnh sửa nếu muốn."
          >
            <Input
              value={generatedSlug}
              onChange={handleSlugChange}
              addonBefore={isSlugEdited && (
                <Button
                  type="text"
                  size="small"
                  onClick={() => {
                    setIsSlugEdited(false);
                    const newSlug = slug(form.getFieldValue('name'));
                    setGeneratedSlug(newSlug);
                    form.setFieldsValue({ slugUrl: newSlug });
                  }}
                >
                  ↺
                </Button>
              )}
            />
          </Form.Item>

          <Form.Item
            name="description"
            label="Mô tả"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="sortNumber"
            label="Thứ tự"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="parentId"
            label="Danh mục cha"
          >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Chọn danh mục cha"
              allowClear
              treeDefaultExpandAll
              treeData={treeData}
              // disabled={editingCategory && editingCategory.children?.length > 0}
              filterTreeNode={(input, node) =>
                node.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>

          <Form.Item className="mb-0 flex justify-end">
            <Button onClick={handleCancel} className="mr-2">
              Hủy
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editingCategory ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Categories;