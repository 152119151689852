import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  message,
  Progress,
  Row,
  Select,
  Tag,
  Tooltip,
  TreeSelect
} from "antd";
import Tags from "../../components/ui/document/tags";
import HeaderContent from "./HeaderContent";
import { addAttachmentToDocument, insertDocument, } from "../../services/document";
import slug from "../../utils/slug";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { getDepartments } from "../../services/department";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import uploadPlugin from "../../utils/editor/uploadPlugin";
import { routes } from "../../utils/constants/routes";
import { LuUpload } from "react-icons/lu";
import { insertTag } from "../../services/tags";
import { chunkedUploader, convertToGMT7, transformToThreeLevelData } from "../../utils/utils";
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from "axios";
import { fetchCategories } from "../../app/features/categorySlice";
import { useDispatch, useSelector } from "react-redux";

function CreateDocument() {
  const dispatch = useDispatch();
  const [draft, setDraft] = useState("");
  const [options, setOptions] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const nav = useNavigate();
  const fileRef = useRef();
  const [messageApi, context] = message.useMessage();
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [status, setStatus] = useState(1);
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState('');
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [form] = Form.useForm();
  const [isSlugEdited, setIsSlugEdited] = useState(false);
  const [generatedSlug, setGeneratedSlug] = useState('');

  const { isSuperUser, isLeader, departmentId } = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );

  const { categories } = useSelector(state => state.category);

  useEffect(() => {
    if (isSuperUser || isLeader) {
    } else nav("/documents");
  }, []);

  useEffect(() => {
    dispatch(fetchCategories({
      page: 1,
      pageSize: 100
    }));
  }, []);

  const clickToSelect = async () => {
    const { data } = await getDepartments() || {};
    if (data) {
      const temps = [];

      if (isSuperUser) {
        data.forEach((dep) => {
          temps.push({ key: dep.Id, value: dep.Id, label: dep.DepartmentName });
        });
        setOptions(temps);
        return;
      }

      if (isLeader) {
        data.forEach((dep) => {
          if (dep.Id === departmentId) {
            temps.push({
              key: dep.Id,
              value: dep.Id,
              label: dep.DepartmentName,
            });
          }
        });
        setOptions(temps);
      }
    }
  };

  const processTagData = async (tagsData) => {
    const tagsList = [];

    await Promise.all(tagsData.map(async (tag) => {
      if (typeof tag === 'string') {
        const { data } = await insertTag({tagName: tag});
        if (data) {
          tagsList.push(data.id);
        }
      } else if (typeof tag === 'number') {
        tagsList.push(tag);
      }
    }));

    return tagsList;
  };

  const onSubmit = async (values) => {
    const tagsList = await processTagData(tagsData);
    const data = {
      ...values,
      contentData: draft,
      ...(values.department && { listDepartmentId: values.department }),
      ...(values.effectiveDate && { effectiveDate : convertToGMT7(values.effectiveDate) }),
      isPublic: values.isPublic == 1 ? true : false,
      isActive: true,
      imagePath: "test-image",
      slugUrl: values.slugUrl,
      tagId: tagsList || [],
    };

    if (!values.department) {
      data.departmentID = departmentId;
    }

    const { data: result, errors } = await insertDocument(data);

    if (!result) {
      messageApi.error(errors.error.join(" | "));
    } else {
      if (uploadedFiles.length > 0) {
        await Promise.all([
          uploadedFiles.map((f) =>
            addAttachmentToDocument({ contentId: result.id, fileId: f.id })
          ),
        ]);
      }

      nav(routes.document);
      messageApi.success("Tạo tài liệu thành công");
    }
  };

  const handleTagsChange = (newTagsData) => {
    setTagsData(newTagsData);
  };

  const uploadAttachment = async () => {
    try {
      if (fileRef.current.files && fileRef.current.files.length > 0) {
        setLoadingUploadFile(true);
        setError('');
        const file = fileRef.current.files[0];

        const source = axios.CancelToken.source();
        setCancelTokenSource(source);

        const res = await chunkedUploader(file, setProgress, source);
        const response = res?.find(item => item?.data?.id != null);

        if (response) {
          if (!response.success) {
            for (let error in response?.errors) {
              response?.errors[error]?.forEach((err) => {
                console.error('Error upload: ', err);
                message.error(err);
                setError(err);
              });
            }
          } else {
            if (response.data) {
              message.success(response?.message);
              setUploadedFiles((prev) => [...prev, response?.data]);
            } else {
              const errText = "Đã có lỗi xảy ra, vui lòng thử lại!"
              console.error('Response does not exist!', response);
              message.error(errText);
              setError(errText);
            }
          }
        } else {
          const errText = "Đã có lỗi xảy ra, vui lòng thử lại!"
          console.error('Response does not exist!');
          message.error(errText);
          setError(errText);
        }

        fileRef.current.value = null;
        fileRef.current.files = null;
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingUploadFile(false);
    }
  };

  const handleCancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Upload cancelled by user.');
    }
  };

  const removeUploadedFile = (itemId) => {
    setUploadedFiles((prev) => [...prev.filter((i) => i.id !== itemId)]);
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    form.setFieldsValue({ title });

    if (!isSlugEdited) {
      const newSlug = slug(title);
      setGeneratedSlug(newSlug);
      form.setFieldsValue({ slugUrl: newSlug });
    }
  };

  const handleSlugChange = (e) => {
    setIsSlugEdited(true);
    const value = e.target.value;
    setGeneratedSlug(value);
  };

  const buildTreeSelectData = (data) => {
    const transformData = (items) => {
      return items.map(item => ({
        title: item.name,
        value: item.id,
        key: item.id,
        children: item.children && item.children.length > 0
          ? transformData(item.children)
          : undefined
      }));
    };

    return transformData(transformToThreeLevelData(data || []));
  };

  return (
    <div>
      {context}
      <HeaderContent>
        <p className="text-xl font-semibold">Tạo tài liệu</p>
      </HeaderContent>
      <div className="py-2">
        <Form
          form={form}
          className="create-document-form"
          onFinish={onSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="title"
                rules={[{ required: true, message: "Tiêu đề không được bỏ trống" }]}
                label={<p className="font-semibold">Tiêu đề</p>}
              >
                <Input
                  size="large"
                  className="rounded-none"
                  placeholder="Ví dụ: Lưu trình làm việc..."
                  onChange={handleTitleChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="slugUrl"
                rules={[
                  { required: true, message: 'Slug URL không được để trống!' },
                  {
                    pattern: /^[a-z0-9_-]+$/,
                    message: 'Slug chỉ được chứa chữ thường, số và dấu gạch dưới/ngang'
                  }
                ]}
                tooltip="Slug sẽ được tạo tự động. Bạn có thể chỉnh sửa nếu muốn."
                label={<p className="font-semibold">Slug</p>}
              >
                <Input
                  value={generatedSlug}
                  onChange={handleSlugChange}
                  size="large"
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="create-document-form-selector flex space-x-14 items-center">
            <Form.Item
              className="w-[300px]"
              name="isPublic"
              rules={[
                {
                  required: true,
                  message: "Trạng thái của tài liệu không được bỏ trống",
                },
              ]}
              label={<p className="font-semibold">Trạng thái</p>}
            >
              <Select
                onChange={(value) => {
                  setStatus(value);
                }}
                placeholder="Lựa chọn trạng thái cho tài liệu"
                size="large"
              >
                <Select.Option key={1}>
                  Công khai - Bất kỳ ai cũng có thể xem
                </Select.Option>
                <Select.Option key={0}>
                  Riêng tư - Chỉ người trong bộ phận được xem
                </Select.Option>
              </Select>
            </Form.Item>
            {status != 1 && (
              <Form.Item
                className="w-[200px]"
                name="department"
                rules={[
                  { required: true, message: "Bộ phận không thể bỏ trống" },
                ]}
                label={
                  <div className="flex">
                    <p className="font-semibold">Bộ phận</p>
                  </div>
                }
              >
                <Select
                  mode="multiple"
                  size="large"
                  onClick={clickToSelect}
                  placeholder="Lựa chọn bộ phận"
                  options={options}
                ></Select>
              </Form.Item>
            )}
          </div>

          <Row className="gap-x-4">
            <Form.Item
              name="categoryId"
              className="w-[600px]"
              label={
                <div className="flex">
                  <p className="font-semibold">Danh mục</p>
                </div>
              }
            >
              <TreeSelect
                allowClear
                size="large"
                placeholder="Chọn danh mục"
                treeLine
                treeDefaultExpandAll
                showSearch
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                filterTreeNode={(inputValue, node) =>
                  node.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                }
                treeData={buildTreeSelectData(categories)}
              />
            </Form.Item>

            <Form.Item
              className="w-[200px]"
              name="effectiveDate"
              label={
                <div className="flex">
                  <p className="font-semibold">Ngày có hiệu lực</p>
                </div>
              }
            >
              <DatePicker
                size="large"
                format="DD/MM/YYYY"
                placeholder="Chọn ngày"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Row>

          <Form.Item
            name="description"
            label={<p className="font-semibold">Mô tả</p>}
          >
            <Input.TextArea
              className="rounded-none"
              size="large"
              rows={5}
              placeholder="Mô tả cho tài liệu này"
            />
          </Form.Item>

          <Form.Item
            name="content"
            label={<p className="font-semibold">Nội dung</p>}
          >
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                fontColor: {
                  colors: [
                    {
                      color: "hsl(0, 0%, 0%)",
                      label: "Black",
                    },
                    {
                      color: "hsl(0, 0%, 30%)",
                      label: "Dim grey",
                    },
                    {
                      color: "hsl(0, 0%, 60%)",
                      label: "Grey",
                    },
                    {
                      color: "hsl(0, 0%, 90%)",
                      label: "Light grey",
                    },
                    {
                      color: "hsl(0, 0%, 100%)",
                      label: "White",
                      hasBorder: true,
                    },
                    // More colors.
                    // ...
                  ],
                },
                fontBackgroundColor: {
                  colors: [
                    {
                      color: "hsl(0, 75%, 60%)",
                      label: "Red",
                    },
                    {
                      color: "hsl(30, 75%, 60%)",
                      label: "Orange",
                    },
                    {
                      color: "hsl(60, 75%, 60%)",
                      label: "Yellow",
                    },
                    {
                      color: "hsl(90, 75%, 60%)",
                      label: "Light green",
                    },
                    {
                      color: "hsl(120, 75%, 60%)",
                      label: "Green",
                    },
                    // More colors.
                    // ...
                  ],
                },
              }}
              editor={Editor}
              onReady={(editor) => {}}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
              onChange={(event, editor) => {
                setDraft(editor.getData());
              }}
              data={draft}
            />
          </Form.Item>
          <Form.Item
            name="files"
            label={<p className="font-semibold">Tệp đính kèm</p>}
          >
            <div className="space-y-2">
              {uploadedFiles.map((item) => (
                <>
                  <div key={item.id} className="flex items-center gap-4">
                    <div className="flex justify-between bg-gray-200 py-2 px-4 rounded-lg w-[240px]">
                      <div className="truncate">
                        <Tooltip title={item?.fileName}>
                          {item?.fileName}
                        </Tooltip>
                      </div>
                      <Tooltip title="Xóa">
                        <span
                          onClick={() => removeUploadedFile(item.id)}
                          className="ml-2 cursor-pointer hover:text-[#666]"
                        >
                          <DeleteOutlined />
                        </span>
                      </Tooltip>
                    </div>
                    <Tag color="green">Vừa tải lên!</Tag>
                  </div>
                </>
              ))}
            </div>
            <Flex align="center" className="mt-4" gap={4}>
              {loadingUploadFile ? (
                <>
                  <span><LoadingOutlined className="mx-1"/> Uploading...</span>
                  <div className="ml-2 relative">
                    <Progress
                      size={40}
                      type="circle"
                      percent={progress}
                    />
                    <span
                      className="p-1 absolute top-[-16px] right-[-16px] cursor-pointer"
                      onClick={handleCancelUpload}
                    >
                      <Tooltip title="Hủy">
                        <span className="font-medium p-2">x</span>
                      </Tooltip>
                    </span>
                  </div>
                </>
              ) : (
                <label
                  htmlFor="uploader"
                  className="px-4 rounded-md bg-gray-300 flex items-center space-x-2 cursor-pointer p-2"
                >
                  <span>Tải lên tệp đính kèm</span> <LuUpload/>
                </label>
              )}
              {error && <span className="text-red-600 ml-2">{error}</span>}
            </Flex>
            <input
              type="file"
              ref={fileRef}
              onChange={uploadAttachment}
              className="!hidden"
              id="uploader"
              name="file"
            />
          </Form.Item>
          <Form.Item
            name="linkGoogleDriver"
            label={<p className="font-semibold">Link google drive</p>}
          >
            <Input
              size="large"
              className="rounded-none"
              placeholder="Link google drive"
            />
          </Form.Item>
          <Form.Item name="tags" label={<p className="font-semibold">Tags</p>}>
            <Tags onChange={handleTagsChange} />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="mt-2">
            Tạo tài liệu
          </Button>
          <Button
            type="primary"
            danger
            className="ml-2"
            onClick={() => nav(routes.document)}
          >
            Hủy bỏ
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default CreateDocument;
